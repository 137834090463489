<!--设置管理模块--通知设置管理页--咨询处-->
<template>
  <div id="inquireSetting" v-loading="loading" :element-loading-text="$t('doorequipment.a12')">
    <!-- 查询栏 -->
    <div class="header">
      <div>
        <a-input v-model="form.info" :placeholder="$t('inquireSetting.a1')" style="width:180px;margin-right:15px"/>
        <a-input v-model="form.updateUser" :placeholder="$t('inquireSetting.a2')" style="width:180px"/>
      </div>
      <div>
        <a-button type="primary" @click="inquire" style="width:120px">{{$t('inquireSetting.a3')}}</a-button>
      </div>
    </div>
    <!-- 表格数据 -->
    <div class="main">
      <a-table 
        :row-selection="allrowSelection"
        :columns="columns" 
        :dataSource="data" 
        :pagination="pagination"
        :scroll="size" 
      >           
      </a-table>         
    </div>
    <!-- 底部功能栏 -->
    <div class="footer">
      <a-popconfirm
          :title="$t('inquireSetting.a4')"
          :ok-text="$t('inquireSetting.a5')"
          :cancel-text="$t('inquireSetting.a6')"
          @confirm="onDelete"
          @cancel="onCancel"
        >
        <a-button type="primary" class="but">{{$t('inquireSetting.a7')}}</a-button>
      </a-popconfirm>   
      <a-button type="primary" class="but" @click="onAdd">{{$t('inquireSetting.a8')}}</a-button>   
      <a-button type="primary" class="but" @click="onChange">{{$t('inquireSetting.a9')}}</a-button>         
    </div>
    <!-- 添加/变更弹框 -->
    <a-modal 
      v-model="visible" 
      :title="name"
      width=600px           
      @ok="handleOk"
      @cancel="cancel"
      :destroyOnClose="true"
      v-drag
    >
      <my-edit :infoForm="infoForm"></my-edit>        
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import tabletitle from "../../../components/Title/tabletitle";
import headerTitle from "../../../components/Title/headerTitleA";
import editModal from './editModal';
import {getInformation,deleteInformation,addInformation,updateInformation,pushMsg} from "../../../api/sysmanage";

export default { 
  data() {
    return {
      name: this.$t('inquireSetting.a10'),
      visible: false,//添加与变更弹窗是否显示
      data: [],
      loading: false,
      size:{ x: 1300, y:500},   
      siteId:0,
      selectedOption:{},
      selectedOptions:[],
      form: {
        siteId: 0,
        updateUser: "",
        info:"",
      },
      infoForm:{
        oldId:"0",//旧信息ID
        msg: "",//信息内容
        ranges:[],//日期时间
        important: true,//是否重要信息
        isAdd:true,//是否新增
        desc:this.$t('inquireSetting.a1'),
      },
      rules: {
        mainUser: [
          { required: true, message: 'Please input mainUser name', trigger: 'blur' },
        ],        
      },
      warningSetting:{},        
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total} ${this.$t("inquireSetting.a11")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 80,
          ellipsis: true,  
          // scopedSlots: { customRender: "id" },
        },
        {
          title: this.$t('inquireSetting.a12'),
          dataIndex: "addMod",
          width: 100,
          ellipsis: true,          
        },
        {
          title: this.$t('inquireSetting.a13'),
          dataIndex: "lastUpdate",
          width: 100,
          ellipsis: true,         
        },
        {
          title: this.$t('inquireSetting.a14'),
          dataIndex: "updateUser",
          width: 100,
          ellipsis: true,
        },   
        {
          title: this.$t('inquireSetting.a1'),
          dataIndex: "inqury",
          width: 100,
          ellipsis: true,
        },  
        {
          title: this.$t('inquireSetting.a15'),
          dataIndex: "showName",
          width: 100,
          ellipsis: true,
        },  
        {
          title: this.$t('inquireSetting.a16'),
          dataIndex: "dispStartDay",
          width: 100,
          ellipsis: true,
        },  
        {
          title: this.$t('inquireSetting.a17'),
          dataIndex: "dispEndDay",
          width: 100,
          ellipsis: true,
        },              
      ],
    };
  }, 
  mounted(){
    this.inquire();//退出更新窗口后，刷新数据 
  },
  computed:{
    allrowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log("inquireSetting selectedRowKeys:", selectedRowKeys);
          console.log("inquireSetting selectedRows:", selectedRows);
          if(selectedRows!=null&&selectedRows.length>0){  
            this.selectedOptions = selectedRows;
            this.selectedOption = selectedRows[0];//取第一个选择项
          }
          console.log("inquireSetting infoForm:", this.infoForm);
        },
      };
    },
  },
  methods: {  
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("inquireSetting");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title =
          indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 66 - title;
        this.size.y = tableHeight;
      }
    },
    setValue(src){
      if(src==null){        
        this.infoForm.oldId = "0";
        this.infoForm.msg="";//信息内容
        this.infoForm.ranges[0]=moment();//开始时间
        this.infoForm.ranges[1]=moment().endOf("month");//结束时间
        this.infoForm.important=false;//是否重要信息
      }else{
        this.infoForm.oldId = src.id;
        this.infoForm.msg=src.inqury;//信息内容
        this.infoForm.ranges[0]=moment(src.dispStartDay);//开始时间
        this.infoForm.ranges[1]=moment(src.dispEndDay);//结束时间
        this.infoForm.important=src.significantMessage;//是否重要信息
      }
    },
    handleOk(){
      console.log("inquireSetting infoForm:", this.infoForm); 
      if(this.infoForm.isAdd){//添加
        this.addInformation();
      }
      else{//变更
        this.updateInformation();
      }
    } ,   
    pushMsg(){
      let par={
        type: 0,
        content:"test",
      }
      console.log("pushMsg:",par);
      pushMsg(par)
        .then((res) => {
          let { data } = res;
          // this.$message.info("推送消息成功"); 
        })
        .catch((err) => {
          console.log(err);
          // this.$message.error("推送消息失败"); 
        });   
    }, 
    onCancel(){
    }, 
    cancel(){
      this.inquire();//退出更新窗口后，刷新数据 
    },
    onDelete() {
      this.deleteInformation();    
      this.inquire();//退出更新窗口后，刷新数据   
    },    
    onAdd() { 
      this.visible = true;  
      this.setValue(null);//初始化页面关联对象
      this.infoForm.isAdd = true;    
    },  

    onChange() {  
      this.visible = true;
      this.setValue(this.selectedOption);//初始化页面关联对象
      this.infoForm.isAdd = false;
    },  
    
    // 变更
    updateInformation() {       
      this.loading = true;  
      let par = {
          oldId:this.infoForm.oldId,//旧信息ID
          msg: this.infoForm.msg,//信息内容
          infoType:2,//1：公共指南；2：咨询处；3：继承事项；4：下载；
          from:moment(this.infoForm.ranges[0]).format("YYYY-MM-DD"),//开始时间
          to:moment(this.infoForm.ranges[1]).format("YYYY-MM-DD"),//结束时间
          juyokbn:this.infoForm.important?"1":"0",//是否重要信息
      };
      console.log("updateInformation par:", par);     
      updateInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateInformation result", data);
          this.$message.info(this.$t('inquireSetting.a18')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('inquireSetting.a19')+err); 
        }); 
    },
    // 添加
    addInformation() {           
      this.loading = true;  
      let infoPar = {
          oldId:this.infoForm.oldId,//旧信息ID
          msg: this.infoForm.msg,//信息内容
          infoType:2,//1：公共指南；2：咨询处；3：继承事项；4：下载；
          from:moment(this.infoForm.ranges[0]).format("YYYY-MM-DD"),//开始时间
          to:moment(this.infoForm.ranges[1]).format("YYYY-MM-DD"),//结束时间
          juyokbn:this.infoForm.important?"1":"0",//是否重要信息
        
      };
      console.log("addInformation par:", infoPar); 
      addInformation(infoPar)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.pushMsg();
          console.log("addInformation result", data);
          this.$message.info(this.$t('inquireSetting.a20')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('inquireSetting.a21')+err); 
        }); 
    },

    // 删除
    deleteInformation() {     
      this.loading = true;      
      let ids = "2";
      if(this.selectedOptions!=null&&this.selectedOptions.length>0){
        for(let i=0;i<this.selectedOptions.length;i++){
            ids = ids +","+this.selectedOptions[i].id;
        }
      }
      let par = {
        param: ids,
      };
      console.log("deleteInformation par", par);
      deleteInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("deleteInformation result", data);
          this.$message.info(this.$t('inquireSetting.a22')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('inquireSetting.a23')+err); 
        }); 
    },    
    // 条件查询方法
    inquire() {           
      this.loading = true;
      this.tableSize()
      let par = {
        updateUser: this.form.updateUser,
        infoType:2,//1：公共指南；2：咨询处；3：继承事项；4：下载；
        info: this.form.info,
      };
      console.log("inquireSetting inquire par:", par); 
      getInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("inquireSetting data", data);
          for (let i = 0; i < data.length; i++) {
            data[i].key = i + 1;
          }
          this.data = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-edit":editModal,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#inquireSetting {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 20px 0px 20px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.main {
  width: 100%;
  height: calc(100% - 87px);
  overflow: auto;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}
.but {
  width: 120px;
  margin-left: 15px;
}
</style>